export const ModalVideo = () => {
  const openButtons = document.querySelectorAll(".interview-slider__link");
  const modal = document.querySelector(".modal-video");
  if (!modal) {
    return
  }
  const container = modal.closest(".fixed-container");
  const modalMask = container.querySelector(".modal-mask");
  const closeButton = document.querySelector(".modal-video__close");
  const videoContainer = document.querySelector(".modal-video__video");

  const playerOpenBtn = document.querySelectorAll(".mini-player__slide");

  //Открытие и закрытие модального окна для мини плеера
  playerOpenBtn.forEach((openButton) => {
    openButton.addEventListener("click", (e) => {
      if (e.target.classList.contains("mini-player__btn") || e.target.closest("svg")) {
        return;
      }
      container.style.display = "flex";
      document.body.classList.add("disable-scroll");
      let url = openButton.dataset.url;
      setupVideo(url);
    });
  });

  //Открытие и закрытие модального окна
  openButtons.forEach((openButton) => {
    openButton.addEventListener("click", () => {
      container.style.display = "flex";
      document.body.classList.add("disable-scroll");
      let url = openButton.dataset.url;
      setupVideo(url);
    });
  });

  // Замена превью картинки на iframe

  function setupVideo(url) {
    let iframe = "";

    if (url.indexOf('.mp4') !== -1) {
      iframe = createVideo(url);
    } else {
      iframe = createIframe(url);
    }
    removeOldPlayer(videoContainer);
    videoContainer.appendChild(iframe);

    //Закрытие модального окна и предотвращение дубилрования EL
    closeButton.addEventListener("click", closeModal);

    modalMask.addEventListener("click", closeModal);
  }

  function closeModal() {
    container.style.display = "none";
    document.body.classList.remove("disable-scroll");

    const frame = document?.querySelector(".modal-video__frame");
    if (frame) {
      frame.remove();
    }
    const video = document?.querySelector(".modal-video__frame--video");
    if (video) {
      video.remove();
    }
  }

  function removeOldPlayer(container) {
    if (!container) {
      return false;
    }

    const iframePlayer = container.querySelector('iframe');
    if (iframePlayer) {
      iframePlayer.remove();
      return true;
    }

    const videoPlayer = container.querySelector('video');
    if (videoPlayer) {
      videoPlayer.remove();
      return true;
    }
    return false;
  }

  function createIframe(link) {
    let iframe = document.createElement("iframe");

    iframe.setAttribute("web-share", "");
    iframe.setAttribute("allowfullscreen", "true");
    iframe.setAttribute("gyroscope", "");
    iframe.setAttribute("accelerometer", "");
    iframe.setAttribute("clipboard-write", "");
    iframe.setAttribute("encrypted-media", "");
    // iframe.setAttribute("allow", "autoplay");
    // iframe.setAttribute("src", link + "&autoplay=1");
    iframe.setAttribute("src", link);
    iframe.classList.add("modal-video__frame");

    return iframe;
  }
  function createVideo(link) {
    let video = document.createElement("video");
    let source = document.createElement("source");
    video.setAttribute("controls", "");
    source.setAttribute("src", link);
    source.setAttribute("type", "video/mp4");
    video.classList.add("modal-video__frame--video");
    video.appendChild(source);
    return video;
  }

  const modalContainer = document.querySelector('.modal-video__video');
  modalContainer.addEventListener('click', (e) => {
    if (e.target.classList.contains('modal-video__video')) {
      closeModal();
    }
  })
};
