import Swiper from "swiper";
import { Navigation, Pagination, Autoplay } from "swiper/modules";

export const InterviewSlider = () => {
  const prevElem = document.querySelector(".interview-slider__prev-button");
  const nextElem = document.querySelector(".interview-slider__next-button");

  const interviewSwiper = new Swiper(".interview-slider", {
    modules: [Navigation, Pagination, Autoplay],
    rewind: true,
    slidesPerView: 2,
    spaceBetween: 8,

    speed: 500,

    autoplay: {
      delay: 3000,
    },

    navigation: {
      nextEl: nextElem,
      prevEl: prevElem,
    },
    pagination: {
      el: ".interview-slider__pagination",
    },

    breakpoints: {
      1024: {
        slidesPerView: 3,
        spaceBetween: 16,
      },
      1280: {
        spaceBetween: 24,
        slidesPerView: 4,
      },
    },
  });
};
