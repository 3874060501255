import { Burger } from "./components/Burger";
import { BurgerMenu } from "./components/BurgerMenu";
import { DropDown } from "./components/DropDown";
import { ProductSlider } from "./components/ProductsSlider";
import { TechPlatform } from "./components/TechPlatform";

import { ReviewsSlider } from "./components/ReviewsSlider";
import { BusinessTeam } from "./components/BusinessTeam";

import { ProcessSlider } from "./components/ProcessSlider";

import { InterviewSlider } from "./components/InterviewSilder";

import { FoundersSlider } from "./components/FoundersSlider";
import { Tabs } from "./components/TabsBlock";
import { BmPlatformSlider } from "./components/BmPlatformSlider";
import { BmProjects } from "./components/BmProjects";
import { CasesSlider } from "./components/CasesSlider";
import { Input } from "./components/Input";
import { DiscountTimer } from "./components/DiscountTimer";
import { MiniPlayer } from "./components/MiniPlayer";
import { Click } from "./components/ClickSpark";
import { ModalVideo } from "./components/ModalVideo";
import { BmServices } from "./components/ServiceSlider";
import { ModalForm } from "./components/ModalForm";
import { SuggestForm } from "./components/SuggestForm";
import { ServicesFormDesktop } from "./components/ServicesForm";
import { ServicesFormMobile } from "./components/ServicesFormMobile";
import { PerformanceSlider } from "./components/PerformanceSlider";

import { ActivitiesSlider } from "./components/ActivitiesSlider";

import { DirectionProjectsSlider } from "./components/DirectionProjectsSlider";

import { DirectionsTable } from "./components/DirectionsTable";

import { TypingTitle } from "./components/TypingTitle";

import { SuccessSlider } from "./components/SuccessSlider";

import { HiddenText } from './components/HiddenText';

import { СaseScroll } from './components/KeisScroll';


/**
 * В setTimeout представлен вариант использования синхронных функций,
 * которые подгружаются только в тот момент, когда они становятся востребованными.
 * Это позволяет уменьшить объёмы изначального бандла index.js.
 * Примером использования, может быть загрузка слайдеров только на тех страницах, где эти слайдеры есть.
 * Или загрузка fancybox только на страницах галереи, либо же там, где она ещё может использовать.
 *
 * К примеру, код шапки не может быть асинхронным, т.к. используется сразу же.
 */
function main() {
  DropDown(); // Скрипты для селектов
  Burger(); // скрипты для открытия бургера
  BurgerMenu(); // Скрипты для внутренних компонетнтов бургера
  ProductSlider(); // Свайпер продуктов на первом экране

  FoundersSlider(); //Слайдер карточек основателей на первом экране

  InterviewSlider(); //Слайдер интервью на первом экране

  ProcessSlider(); //Слайдер процесса работы на первом экране
  ReviewsSlider(); //Слайдер отзывов на первом экране

  TechPlatform(); // Свайпел для слайдера "Технологическая платформа для"

  BusinessTeam(); // Свайпер для блока "Бизнес компания"

  Tabs(); // Обработчик табов

  BmPlatformSlider(); // Слайдер таблица блока "Платформа"

  BmProjects(); // Скрипты для блока "Проекты, сделанные в BusinessMentor"

  CasesSlider(); // Вертикальный/горизонтальый слайдер с кейсами

  Input(); // Обрабока положения лейбла инпута при ввоте текста

  DiscountTimer(); // Таймер в форме

  MiniPlayer();

  Click(); // Эффект клика, необходимо добавлять на страницу компонент click-spark, чтобы он работал

  BmServices();

  ModalVideo();

  ModalForm(); //Взаимодействие и валидация формы в модальном окне

  SuggestForm(); //Взаимодействие и валидация формы главной страницы

  ServicesFormDesktop(); //Взаимодействие и валидация формы блока сервисы

  ServicesFormMobile(); //Взаимодействие и валидация формы блока сервисы на мобильном расширении

  PerformanceSlider(); //Слайдер первого блока страницы направлений

  ActivitiesSlider(); //Слайдер направлений деятельности компании

  DirectionProjectsSlider(); //Слайдер проектов компании на странице направлений

  DirectionsTable();

  TypingTitle();

  SuccessSlider();

  HiddenText();//Показать весь текст в блоке скрытый текст

  СaseScroll();
}

document.addEventListener("DOMContentLoaded", main);
