export const HiddenText = () => {
    const btns = document.querySelectorAll('.hidden-text__show-more');

    btns.forEach((moreBtn) => {
        moreBtn.addEventListener("click", () => {
            const activeMoreText = document.querySelector(".hidden__container.--show");
            const moreText = document.querySelector(".hidden__container");
            console.log(activeMoreText);
            if (activeMoreText) {
                moreBtn.classList.remove("--show");
                activeMoreText.classList.remove("--show");
                moreBtn.querySelector("span").textContent = "Развернуть";
            }
            else if (moreText) {
                moreBtn.classList.add("--show");
                moreBtn.querySelector("span").textContent = "Свернуть";
                moreText.classList.add("--show");
            }
        });
    });
};

