import Swiper from "swiper";
import { Navigation, EffectFade } from "swiper/modules";

export const MiniPlayer = () => {
  const playerContainers = document.querySelectorAll('.mini-player');
  if (!playerContainers) {
    return
  }

  playerContainers.forEach((player) => {
    const swiperContainer = player.querySelector('.mini-player__container.swiper');
    const nextElem = player.querySelector('.mini-player__nav_btn.--next');
    const prevElem = player.querySelector('.mini-player__nav_btn.--prev');

    const miniPlayer = new Swiper(swiperContainer, {
      modules: [Navigation, EffectFade],
      rewind: true,
      slidesPerView: 1,
      effect: 'fade',
      allowTouchMove: false,
      navigation: {
        nextEl: nextElem,
        prevEl: prevElem,
      },
    });

    miniPlayer.on('slideChange', () => {
      stopVideo(miniPlayer.slides[miniPlayer.previousIndex]);
    })
  })

  const playBtns = document.querySelectorAll('button.mini-player__btn.play-pause__btn');
  playBtns.forEach((playBtn) => {
    playBtn.addEventListener('click', () => {
      const container = playBtn.closest('.mini-player__slide');
      if (!container) {
        return;
      }

      if (container.classList.contains('--play')) {
        stopVideo(container);
      } else {
        playVideo(container);
      }
    })
  })

  function stopVideo(slide) {
    slide.classList.remove('--play');
    let video = slide.querySelector('video');
    if (video) {
      video.pause();
      return;
    }

    video = slide.querySelector('iframe');
    if (!video) {
      return;
    }
    const iframe = video.contentWindow;
    iframe.postMessage(
      '{"event":"command","func":"' + 'pauseVideo' + '","args":""}',
      "*"
    );
  }
  function playVideo(slide) {
    slide.classList.add('--play');
    let video = slide.querySelector('video');
    if (video) {
      video.play();
      return;
    }

    video = slide.querySelector('iframe');
    if (!video) {
      return;
    }
    const iframe = video.contentWindow;
    iframe.postMessage(
      '{"event":"command","func":"' + 'playVideo' + '","args":""}',
      "*"
    );
  }

  function muteVideo(slide) {
    slide.classList.add('--mute');
    let video = slide.querySelector('video');
    if (video) {
      video.muted = true;
      return;
    }

    video = slide.querySelector('iframe');
    if (!video) {
      return;
    }
    const iframe = video.contentWindow;
    iframe.postMessage(
      '{"event":"command","func":"' + 'mute' + '","args":""}',
      "*"
    );
  }
  function unmuteVideo(slide) {
    slide.classList.remove('--mute');
    let video = slide.querySelector('video');
    if (video) {
      video.muted = false;
      return;
    }

    video = slide.querySelector('iframe');
    if (!video) {
      return;
    }
    const iframe = video.contentWindow;
    iframe.postMessage(
      '{"event":"command","func":"' + 'unMute' + '","args":""}',
      "*"
    );
  }


  // const muteBtns = document.querySelectorAll('button.mini-player__btn.sound__btn');
  // muteBtns.forEach((muteBtn) => {
  //   muteBtn.addEventListener('click', () => {
  //     const container = muteBtn.closest('.mini-player__slide');
  //     if (!container) {
  //       return;
  //     }

  //     if (container.classList.contains('--mute')) {
  //       unmuteVideo(container)
  //     } else {
  //       muteVideo(container)
  //     }
  //   })
  // })
}