import Swiper from "swiper";
import { Navigation, Autoplay } from "swiper/modules";

export function SuccessSlider() {
  const successSlider = new Swiper(".success__slider", {
    modules: [Navigation, Autoplay],
    // rewind: true,
    loop: true,
    slidesPerView: "auto",
    spaceBetween: 12,
    centeredSlides: true,
    speed: 1000,

    autoplay: {
      delay: 3000,
    },

    navigation: {
      nextEl: ".success-slider-next",
      prevEl: ".success-slider-prev",
    },

    breakpoints: {
      // when window width is >= 744px
      744: {
        spaceBetween: 12,
      },
      // when window width is >= 1024px
      1024: {
        spaceBetween: 24,
      },
      // when window width is >= 1280px
      1440: {
        spaceBetween: 24,
      },
    },
  });
}
