export const Input = () => {
  const isFilled = (evt) => {
    if (evt.target.value === "") {
      evt.target.classList.remove("--filled");
    } else {
      evt.target.classList.add("--filled");
    }
  };

  const inputs = document.querySelectorAll("input.suggest__input");

  inputs.forEach((inputElem) => {
    inputElem.addEventListener("change", isFilled);
  });

  const serviceInputs = document.querySelectorAll(".services-form__input");

  serviceInputs.forEach((inputElem) => {
    inputElem.addEventListener("change", isFilled);
  });

  const modalInputs = document.querySelectorAll(".modal-form__input");

  modalInputs.forEach((inputElem) => {
    inputElem.addEventListener("change", isFilled);
  });
};
