// Принцип работы табов
// У каждой кнопки "таба" есть атрибут data-tabid="Идентефикатор таба"
// С помощью него находится нужный контейнер(контейнер должен иметь атрибут data-tabcontainer="Идентефикатор таба")
// Все контейнеры на одном уровне с найденым будут скрыты, путем удаления класса "--show", отвечающего за показ блока
// Далее на найденный/нужный контейнер добавится класс "--show"

export const Tabs = () => {
  const pageTabs = document.querySelectorAll('.tabs-block .tabs-block__tab');
  pageTabs.forEach((tab) => {
    tab.addEventListener("click", () => {
      const currentContainer = document.querySelector(`[data-tabcontainer="${tab.dataset.tabid}"]`);

      if (!currentContainer) {
        return;
      }

      const containerWrapper = currentContainer.parentElement;
      const opendContainer = containerWrapper.querySelector('.bm-projects__tables_container.--show');
      if (!opendContainer) {
        return;
      }

      opendContainer.classList.remove('--show');

      currentContainer.classList.add('--show');

      const tabsContainer = tab.closest('.tabs-block__container');
      const activeTab = tabsContainer.querySelector('.--current');
      activeTab.classList.remove('--current')
      tab.classList.add('--current')
    });
  });
}