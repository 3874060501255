import Inputmask from "inputmask";
export const SuggestForm = () => {
  const form = document.querySelector(".suggest__form");
  if (!form) {
    return;
  }

  const modalSuccessMessage = document.querySelector(
    ".success-container--global"
  );

  const modalSuccessClose = modalSuccessMessage.querySelector(
    ".modal-success__close"
  );
  const modalSuccessButton = modalSuccessMessage.querySelector(
    ".modal-success__button"
  );

  const userName = document.querySelector("#name");
  const nameField = userName.closest(".suggest__input-wrapper");

  const userEmail = document.querySelector("#email");
  const emailField = userEmail.closest(".suggest__input-wrapper");

  const userPhone = document.querySelector("#phone");
  const phoneField = userPhone.closest(".suggest__input-wrapper");

  const agreementField = form
    .querySelector(".suggest__checkbox")
    .closest(".suggest__agreement");

  const telField = form.querySelector('input[type="tel"]');
  const letMask = new Inputmask("+7 (999) 999-99-99", {
    showMaskOnHover: false,
  });
  letMask.mask(telField);

  const nameReg = /^[A-Za-zА-Яа-яЁё]+$/;
  const emailReg = /^[A-Za-z0-9@.]+$/;
  const phoneReg = /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/;
  const emailReg2 = /[@]/;

  const clearFields = () => {
    const fields = form.querySelectorAll(".suggest__input");
    fields.forEach((field) => {
      field.value = "";
    });
  };

  form.addEventListener("submit", (evt) => {
    evt.preventDefault();

    if (userName.value.length === 0 || !nameReg.test(userName.value)) {
      nameField.classList.add("invalid-field");

      setTimeout(() => {
        nameField.classList.remove("invalid-field");
      }, 3000);
      return;
    } else if (
      userEmail.value.length === 0 ||
      !emailReg.test(userEmail.value) ||
      !emailReg2.test(userEmail.value)
    ) {
      emailField.classList.add("invalid-field");
      setTimeout(() => {
        emailField.classList.remove("invalid-field");
      }, 3000);
      return;
    } else if (
      userPhone.value.length === 0 ||
      !phoneReg.test(userPhone.value)
    ) {
      phoneField.classList.add("invalid-field");
      setTimeout(() => {
        phoneField.classList.remove("invalid-field");
      }, 3000);
      return;
    } else if (!form.querySelector(".suggest__checkbox:checked")) {
      agreementField.classList.add("invalid-field");
      setTimeout(() => {
        agreementField.classList.remove("invalid-field");
      }, 3000);
      return;
    }

    modalSuccessMessage.style.display = "flex";
    clearFields();

    modalSuccessButton.addEventListener("click", () => {
      modalSuccessMessage.style.display = "none";
    });
    modalSuccessClose.addEventListener("click", () => {
      modalSuccessMessage.style.display = "none";
    });
  });
};
