export const BurgerMenu = () => {
  const header = document.querySelector('.header__container');

  const mainMenu = document.querySelector('.burger-menu__main');
  const mainMenuItems = document.querySelectorAll('.burger-menu__main_menu_item');
  let lastCurrentItemMain = document.querySelector('.burger-menu__main_menu_item.--current');

  // Меню первого уровня
  mainMenuItems.forEach((menuItem) => {
    menuItem.addEventListener('mouseenter', () => {
      if (window.innerWidth < 1024) {
        return
      }
      menuItem.classList.add('--current');
      if (lastCurrentItemMain !== null) {
        lastCurrentItemMain.classList.remove('--current');
      }
      lastCurrentItemMain = menuItem;

      const opendContainer = document.querySelector('.burger-menu__content.--open');
      if (opendContainer) {
        opendContainer.classList.remove('--open');
      }

      const pickedElem = document.querySelector(`.burger-menu__content[data-main-content="${menuItem.dataset.main}"]`)
      if (pickedElem) {
        pickedElem.classList.add('--open');
      }
    });
    menuItem.addEventListener('click', () => {
      if (window.innerWidth >= 1024) {
        return
      }
      mainMenu.classList.add('--hide');
      header.classList.add('--sub-open');
      const opendContainer = document.querySelector('.burger-menu__content.--open');
      if (opendContainer) {
        opendContainer.classList.remove('--open');
      }

      const pickedElem = document.querySelector(`.burger-menu__content[data-main-content="${menuItem.dataset.main}"]`)
      if (pickedElem) {
        pickedElem.classList.add('--open');
      }
    });
  });

  // Меню второго уровня
  const subMenu = document.querySelector('.burger-menu__sub_menu');
  const subMenuItems = document.querySelectorAll('.burger-menu__sub_menu .burger-menu__sub_menu_item');
  let lastCurrentItemSub = document.querySelector('.burger-menu__sub_menu_item.--current');

  subMenuItems.forEach((menuItem) => {
    // Наведение на элемент внутреннего меню
    menuItem.addEventListener('mouseenter', () => {
      if (window.innerWidth < 1024) {
        return
      }
      menuItem.classList.add('--current');
      if (lastCurrentItemSub !== null) {
        lastCurrentItemSub.classList.remove('--current');
      }
      lastCurrentItemSub = menuItem;

      const menuContainer = menuItem.closest('.burger-menu__content');

      const opendContent = menuContainer.querySelector('.burger-menu__content_sub_wrapper.--open');
      if (opendContent) {
        opendContent.classList.remove('--open');
      }
    });

    // Клик на элемент внутреннего меню
    menuItem.addEventListener('click', () => {
      if (menuItem.classList.contains('--back')) {
        mainMenu.classList.remove('--hide');
        header.classList.remove('--sub-open');
        return
      }
      if (window.innerWidth >= 1024) {
        return
      }

      const subMenu = menuItem.closest('.burger-menu__sub_menu')
      subMenu.classList.add('--hide');

      const menuContainer = menuItem.closest('.burger-menu__content');

      const opendContent = menuContainer.querySelector('.burger-menu__content_sub_wrapper.--open');
      if (opendContent) {
        opendContent.classList.remove('--open');
      }
    });
  });

  const lastMenuItems = document.querySelectorAll('.menu-list__title');
  lastMenuItems.forEach((menuItem) => {
    // Клик на элемент последнего меню(3 уровень)
    menuItem.addEventListener('click', () => {
      const itemContainer = menuItem.closest('.menu-list__item');
      const itemLinks = itemContainer.querySelector('.menu-list__links .menu-list__links_container');
      if (window.innerWidth >= 1024 || !itemLinks) {
        return
      }
      itemContainer.classList.toggle('--open');

      if (itemContainer.classList.contains('--open')) {
        itemLinks.style.height = '100%';
        itemLinks.style.marginTop = '16px';
      } else {
        itemLinks.style.height = '';
        itemLinks.style.marginTop = '';
      }
    });
  });

  // Кнопка показать еще
  const moreBtns = document.querySelectorAll('.menu-list__links_more');
  moreBtns.forEach((moreBtn) => {
    moreBtn.addEventListener("click", () => {
      const linksContainer = moreBtn.closest('.menu-list__links');
      if (linksContainer) {
        linksContainer.classList.add('--open');
      }
      moreBtn.style.display = 'none';
    })
  })

  const lastMenuBack = document.querySelectorAll('.menu-list .burger-menu__sub_menu_item.--back');
  lastMenuBack.forEach((backBtn) => {
    backBtn.addEventListener("click", () => {
      mainMenu.classList.remove('--hide');
      header.classList.remove('--sub-open');
    })
  })
}