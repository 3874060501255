import Swiper from "swiper";
import { Pagination, Autoplay, Grid } from "swiper/modules";

export const TechPlatform = () => {
  const footerSwiper = new Swiper(".tech-platform .swiper", {
    modules: [Pagination, Autoplay, Grid],
    rewind: true,
    slidesPerView: 2,
    spaceBetween: 8,

    grid: {
      fill: "row",
      rows: 2,
    },

    speed: 500,

    autoplay: {
      delay: 3000,
    },

    pagination: {
      el: ".tech-platform__pagination",
    },

    breakpoints: {
      576: {
        spaceBetween: 11,
        slidesPerView: "auto",
        grid: {
          fill: "row",
          rows: 1,
        },
      },
      1024: {
        spaceBetween: 16,
        slidesPerView: "auto",
        grid: {
          fill: "row",
          rows: 1,
        },
      },
      1440: {
        spaceBetween: 24,
        slidesPerView: "auto",
        grid: {
          fill: "row",
          rows: 1,
        },
      },
    },
  });


  // Обработка анимации мечатанья текста
  const inViewport = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add("--animate");
        setTimeout(textSwitching, 4000);
      }
    });
  };

  const obsOptions = {};

  const Obs = new IntersectionObserver(inViewport, obsOptions);

  document.querySelectorAll(".tech-platform__typing_text").forEach((el) => {
    Obs.observe(el);
  });

  const textsList = [
    "для интернет магазина",
    "для BusinessMentor",
    "для IT-продукта"
  ];
  let textListIndex = 1;
  const textDomElem = document.querySelector('span.tech-platform__typing_text_sub');
  function textSwitching() {
    if (!textDomElem) {
      return false
    }
    let isSwitched = false;
    const textSwitchinterval = setInterval(() => {

      if (textDomElem.offsetWidth < 10) {
        if (!isSwitched) {
          textDomElem.innerHTML = textsList[textListIndex];
          textListIndex = textsList.length - 1 === textListIndex ? 0 : textListIndex + 1;
          isSwitched = true;
        }
      } else {
        isSwitched = false
      }
    }, 100);
  }
};
