export const DropDown = () => {
  const dropdownElems = document.querySelectorAll(".dropdown-select");
  const transition = 200;
  let isAnimated = false;
  dropdownElems.forEach((dropdown) => {
    const dropdownhead = dropdown.querySelector(".dropdown-select__head");
    const dropdownbody = dropdown.querySelector(".dropdown-select__body");
    const dropdownbodyContainer = dropdown.querySelector(
      ".dropdown-select__body_container"
    );
    const dropdownbodyItems = dropdown.querySelectorAll(
      ".dropdown-select__body_item"
    );
    const dropdownText = dropdownhead.querySelector(".dropdown-select__text");

    dropdownhead.addEventListener("click", () => {
      if (isAnimated) {
        return false;
      } else {
        isAnimated = true;
      }
      if (dropdown.classList.contains("--open")) {
        closePopup(dropdown, dropdownbody, dropdownbodyContainer);
      } else {
        openPopup(dropdown, dropdownbody, dropdownbodyContainer);
      }
    });

    dropdownbodyItems.forEach((dropdownbodyItem) => {
      dropdownbodyItem.addEventListener("click", () => {
        for (let i = 0; i < dropdownbodyItems.length; i++) {
          if (dropdownbodyItems[i].classList.contains("--current")) {
            dropdownbodyItems[i].classList.remove("--current");
            break;
          }
        }
        dropdownbodyItem.classList.add("--current");
        closePopup(dropdown, dropdownbody, dropdownbodyContainer);
        dropdownText.innerHTML = dropdownbodyItem.querySelector(
          ".dropdown-select__text"
        ).innerHTML;
        if (dropdownText.textContent.toLowerCase() !== "тип продукта") {
          dropdownText.style.color = "#ffffff";
        }
      });
    });
  });

  function closePopup(dropdown, body, bodyContainer) {
    body.style.height = `${bodyContainer.offsetHeight}px`;
    body.style.transition = `${transition}ms`;

    setTimeout(() => {
      body.style.height = 0;
    }, 1);
    setTimeout(() => {
      dropdown.classList.remove("--open");
      isAnimated = false;
    }, transition);
  }
  function openPopup(dropdown, body, bodyContainer) {
    body.style.height = `${bodyContainer.offsetHeight}px`;
    body.style.transition = `${transition}ms`;
    setTimeout(() => {
      body.style.height = "auto";
      isAnimated = false;
      dropdown.classList.add("--open");
    }, transition);
  }
};
