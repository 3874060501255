import Swiper from "swiper";
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

export const ProductSlider = () => {
  const nextElem = document.querySelector('.products-slider .products-slider__navigation.--right');
  const prevElem = document.querySelector('.products-slider .products-slider__navigation.--left');
  const pagination = document.querySelector('.products-slider .products-slider__pagination');

  const footerSwiper = new Swiper(".products-slider .swiper", {
    modules: [Navigation, Pagination, Autoplay],
    rewind: true,
    slidesPerView: 'auto',
    spaceBetween: 8,

    speed: 500,

    autoplay: {
      delay: 3000,
    },

    navigation: {
      nextEl: nextElem,
      prevEl: prevElem,
    },
    pagination: {
      el: pagination,
    },
    breakpoints: {
      375: {
        slidesPerView: 2,
        paceBetween: 16,
      },
      576: {
        slidesPerView: 3,
      },
      1024: {
        slidesPerView: 4,
      },
    }
  });
}
