import Inputmask from "inputmask";

export const ServicesFormMobile = () => {
  const form = document.querySelectorAll(".services-form__form--mobile");
  if (!form) {
    return;
  }
  for (let i = 0; i < form.length; i++) {
    const currentForm = form[i];

    const modalSuccessMessage = document.querySelector(
      ".success-container--global"
    );

    const modalSuccessClose = modalSuccessMessage.querySelector(
      ".modal-success__close"
    );
    const modalSuccessButton = modalSuccessMessage.querySelector(
      ".modal-success__button"
    );

    const userName = currentForm.querySelector("#name--services-mobile");
    const nameField = userName.closest(".services-form__field");

    const userEmail = currentForm.querySelector("#email--services-mobile");
    const emailField = userEmail.closest(".services-form__field");

    const userPhone = currentForm.querySelector("#tel--services-mobile");
    const phoneField = userPhone.closest(".services-form__field");
    const telField = currentForm.querySelector('input[type="tel"]');
    const letMask = new Inputmask("+7 (999) 999-99-99", {
      showMaskOnHover: false,
    });
    letMask.mask(telField);

    const nameReg = /^[A-Za-zА-Яа-яЁё]+$/;
    const phoneReg = /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/;
    const emailReg = /^[A-Za-z0-9@.]+$/;
    const emailReg2 = /[@]/;

    const clearFields = () => {
      const fields = currentForm.querySelectorAll(".suggest__input");
      fields.forEach((field) => {
        field.value = "";
      });
    };

    currentForm.addEventListener("submit", (evt) => {
      evt.preventDefault();

      if (userName.value.length === 0 || !nameReg.test(userName.value)) {
        nameField.classList.add("invalid-field");

        setTimeout(() => {
          nameField.classList.remove("invalid-field");
        }, 3000);
        return;
      } else if (
        userPhone.value.length === 0 ||
        !phoneReg.test(userPhone.value)
      ) {
        phoneField.classList.add("invalid-field");
        setTimeout(() => {
          phoneField.classList.remove("invalid-field");
        }, 3000);
        return;
      } else if (
        userEmail.value.length >= 1 &&
        (!emailReg.test(userEmail.value) ||
          !emailReg2.test(userEmail.value))
      ) {
        emailField.classList.add("invalid-field");
        setTimeout(() => {
          emailField.classList.remove("invalid-field");
        }, 3000);
        return;
      }

      modalSuccessMessage.style.display = "flex";
      clearFields();

      modalSuccessButton.addEventListener("click", () => {
        modalSuccessMessage.style.display = "none";
      });
      modalSuccessClose.addEventListener("click", () => {
        modalSuccessMessage.style.display = "none";
      });
    });
  };
};

