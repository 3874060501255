import Swiper from "swiper";
import { Navigation, Pagination, Autoplay, Grid } from "swiper/modules";

export function DirectionProjectsSlider() {
  const projectsSlider = new Swiper(".projects-slider", {
    modules: [Navigation, Pagination, Autoplay, Grid],
    rewind: true,
    slidesPerView: 2,
    spaceBetween: 8,

    grid: {
      fill: "row",
      rows: 2,
    },

    speed: 1000,

    autoplay: {
      delay: 3000,
    },

    navigation: {
      nextEl: ".projects-slider-next",
      prevEl: ".projects-slider-prev",
    },
    pagination: {
      el: ".projects-slider-pagination",
    },

    breakpoints: {
      // when window width is >= 744px
      744: {
        slidesPerView: 3,
        spaceBetween: 12,
        grid: {
          fill: "row",
          rows: 2,
        },
      },
      // when window width is >= 1024px
      1024: {
        slidesPerView: 5,
        spaceBetween: 16,
        grid: {
          fill: "row",
          rows: 2,
        },
      },
      // when window width is >= 1280px
      1439: {
        slidesPerView: 6,
        spaceBetween: 16,
        grid: {
          fill: "row",
          rows: 1,
        },
      },
    },
  });
}
