import Swiper from "swiper";
import { Pagination, Autoplay, Grid } from "swiper/modules";

export const BmServices = () => {
  const showMoreBtns = document.querySelectorAll(".services-item__show-more");

  showMoreBtns.forEach((el) => {
    el.addEventListener("click", (evt) => {
      const activeItem = document.querySelector(
        ".services-item__container.--show"
      );
      const currentItem = evt.currentTarget.closest(
        ".services-item__container"
      );

      if (activeItem) {
        if (activeItem !== currentItem) {
          activeItem.classList.remove("--show");
          activeItem.style.maxHeight = null;
          activeItem.querySelector(
            ".services-item__show-more span"
          );

          activeItem.closest(".services-item").style.zIndex = "100";
        }
      }

      const serviceItem = el.closest(".services-item");
      const serviceContainer = serviceItem.querySelector(
        ".services-item__container"
      );

      serviceContainer.classList.toggle("--show");

      if (serviceContainer.classList.contains("--show")) {
        serviceItem.style.zIndex = "1000";
        el.querySelector("span").classList.add("none")
        serviceContainer.style.maxHeight = `${serviceContainer.scrollHeight}px`;
      } else {
        serviceItem.style.zIndex = "100";
        el.querySelector("span").classList.remove("none");

        serviceContainer.style.maxHeight = null;
      }
    });
  });
  const resizableSwiper = (breakpoint, swiperClass, swiperSettings) => {
    let swiper;

    breakpoint = window.matchMedia(breakpoint);

    const enableSwiper = function (className, settings) {
      swiper = new Swiper(className, settings);
    };

    const checker = function () {
      if (breakpoint.matches) {
        return enableSwiper(swiperClass, swiperSettings);
      } else {
        if (swiper !== undefined) swiper.destroy(true, true);
        return;
      }
    };

    breakpoint.addEventListener("change", checker);
    checker();
  };

  resizableSwiper("(max-width: 743px)", ".services__slider", {
    modules: [Pagination, Autoplay, Grid],
    rewind: true,
    spaceBetween: 8,
    slidesPerView: "auto",


    grid: {
      fill: "row",
      rows: 2,
    },

    speed: 1000,


    pagination: {
      el: ".services__slider-pagination",
    },
  });
};
