import Swiper from "swiper";
import { Pagination, Autoplay, Grid } from "swiper/modules";

export const BmProjects = () => {
  const showMoreBtns = document.querySelectorAll(
    ".bm-projects .bm-projects__table_show-more"
  );
  const hideMoreBtns = document.querySelectorAll(
    ".bm-projects .bm-projects__table_hide-more"
  );
  showMoreBtns.forEach((moreBtn) => {
    moreBtn.addEventListener("click", () => {
      const tableBlock = moreBtn.closest(".bm-projects__table");
      tableBlock.classList.add("--show");
    });
  });
  hideMoreBtns.forEach((hideBtn) => {
    hideBtn.addEventListener("click", () => {
      const tableBlock = hideBtn.closest(".bm-projects__table");
      tableBlock.classList.remove("--show");
    });
  });

  const bmProjectsSwipers = document.querySelectorAll(
    ".bm-projects .bm-projects__swiper"
  );
  bmProjectsSwipers.forEach((swiperContainer) => {
    const swiper = swiperContainer.querySelector(
      ".bm-projects__swiper_container.swiper"
    );
    const pagination = swiperContainer.querySelector(
      ".bm-projects__pagination.pagination"
    );
    const bmProjectsSwiper = new Swiper(swiper, {
      modules: [Pagination, Autoplay, Grid],
      rewind: true,
      slidesPerView: 2,
      spaceBetween: 8,

      grid: {
        fill: "row",
        rows: 2,
      },

      speed: 1000,

      // autoplay: {
      //   delay: 3000,
      // },

      pagination: {
        el: pagination,
      },

      breakpoints: {
        744: {
          spaceBetween: 16,
        },
      },
    });
  });
};
