import Swiper from "swiper";
import { Navigation } from 'swiper/modules';

export const CasesSlider = () => {
  let currentWidht = window.innerWidth;

  const bmProjectsSwiper = new Swiper('.main-banner__cases_swiper.swiper', {
    modules: [Navigation],
    slidesPerView: "auto",
    spaceBetween: 12,
    direction: 'horizontal',
    navigation: {
      nextEl: ".main-banner__cases_nav.--bot",
      prevEl: ".main-banner__cases_nav.--top",
    },
    breakpoints: {
      1024: {
        spaceBetween: 8,
        direction: 'vertical',
      },
    }
  });

  window.addEventListener("resize", (event) => {
    if (window.innerWidth < 1024 && currentWidht >= 1024 || window.innerWidth >= 1024 && currentWidht < 1024) {
      currentWidht = window.innerWidth;
      if (bmProjectsSwiper) {
        bmProjectsSwiper.update();
      }
    }
  });

}