import Swiper from "swiper";
import { Navigation, Pagination, Autoplay } from "swiper/modules";

export function PerformanceSlider() {
  const footerSwiper = new Swiper(".performance-slider", {
    modules: [Navigation, Pagination, Autoplay],
    rewind: true,
    slidesPerView: "auto",
    spaceBetween: 20,
    speed: 500,

    autoplay: {
      delay: 3000,
    },

    navigation: {
      nextEl: ".performance__next-button",
      prevEl: ".performance__prev-button",
    },
    pagination: {
      el: ".performance__pagination",
    },
  });
}
