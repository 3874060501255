import Swiper from "swiper";
import { Navigation, Pagination, Autoplay, Grid } from "swiper/modules";

export const ProcessSlider = () => {
  const prevElem = document.querySelector(".process-slider__prev-button");
  const nextElem = document.querySelector(".process-slider__next-button");

  const processSwiper = new Swiper(".process-slider", {
    modules: [Navigation, Pagination, Autoplay, Grid],
    rewind: true,
    slidesPerView: 2,
    spaceBetween: 8,

    grid: {
      fill: "row",
      rows: 2,
    },

    speed: 500,

    autoplay: {
      delay: 3000,
    },

    navigation: {
      nextEl: nextElem,
      prevEl: prevElem,
    },
    pagination: {
      el: ".process-slider__pagination",
    },

    breakpoints: {
      1024: {
        slidesPerView: 4,
        spaceBetween: 16,
        grid: {
          fill: "row",
          rows: 1,
        },
      }
    }
  });
};
