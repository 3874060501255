export const TypingTitle = () => {
  let textListIndex = 1;
  const textsList = [
    "IT-разработка",
    "Маркетинг",
    "Дизайн",
    "Готовые ПО",
  ];
  const textDomElem = document.querySelector('span.preview-title__typing');
  if (!textDomElem) {
    return false
  }
  const innerText = textDomElem.querySelector('span.typing');

  let isSwitched = false;
  const textSwitchinterval = setInterval(() => {

    if (textDomElem.offsetWidth < 10) {
      if (!isSwitched) {
        innerText.innerHTML = textsList[textListIndex];
        textListIndex = textsList.length - 1 === textListIndex ? 0 : textListIndex + 1;
        isSwitched = true;
      }
    } else {
      isSwitched = false
    }
  }, 100);
}