import Swiper from "swiper";
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

export const BusinessTeam = () => {
  const nextElem = document.querySelector('.business-team .products-slider__navigation.--right');
  const prevElem = document.querySelector('.business-team .products-slider__navigation.--left');
  const pagination = document.querySelector('.business-team .business-team__pagination');

  const footerSwiper = new Swiper(".business-team .swiper", {
    modules: [Navigation, Pagination, Autoplay],
    rewind: true,
    slidesPerView: "auto",
    spaceBetween: 8,
    slidesPerGroup: 1,
    // speed: 1000,

    autoplay: {
      delay: 3000,
    },

    navigation: {
      nextEl: nextElem,
      prevEl: prevElem,
    },
    pagination: {
      el: pagination,
    },
    breakpoints: {
      744: {
        slidesPerView: 3,
        spaceBetween: 8,
      },
      1023: {
        slidesPerView: 3,
        spaceBetween: 16,
      },
      1440: {
        slidesPerGroup: 2,
        slidesPerView: 4,
        spaceBetween: 24,
      },
    },
  });
}