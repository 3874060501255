import Swiper from "swiper";
import { Navigation, Pagination, Autoplay } from "swiper/modules";

export const FoundersSlider = () => {


  const founderswSwiper = new Swiper(".founders-slider", {
    modules: [Navigation, Pagination, Autoplay],
    rewind: true,
    slidesPerView: "auto",
    spaceBetween: 8,

    speed: 500,

    autoplay: {
      delay: 3000,
    },


    pagination: {
      el: ".founders-slider__pagination",
    },

    breakpoints: {
      743: {
        slidesPerView: 2,
        spaceBetween: 12,
      },
    }
  });
};
