import Swiper from "swiper";
import { Navigation, Pagination, Autoplay } from "swiper/modules";

export function DirectionsTable() {
  if (window.location.href.includes("directions")) {
    const accordionBtns = document.querySelectorAll("[data-accordion-button]");
    if (accordionBtns) {
      accordionBtns.forEach((btn) => {
        btn.addEventListener("click", () => {
          const accordion = btn.closest("[data-accordion]");
          const accordionBody = accordion.querySelector(
            "[data-accordion-body]"
          );

          accordion.classList.toggle("open");
          btn.classList.toggle("open");

          if (accordionBody.style.maxHeight) {
            accordionBody.style.maxHeight = accordionBody.scrollHeight + "px";
            setTimeout(() => {
              accordionBody.style.maxHeight = null;
            }, 1);
          } else {
            accordionBody.style.maxHeight = accordionBody.scrollHeight + "px";
            setTimeout(() => {
              accordionBody.style.maxHeight = "unset";
            }, 300);
          }
        });
      });
    }

    const slidersContainer = document.querySelectorAll(
      ".directions-table__slider"
    );

    slidersContainer.forEach((el) => {
      const slider = el.querySelector(".table-slider");
      const prevButton = el.querySelector(".table-slider-prev");
      const nextButton = el.querySelector(".table-slider-next");
      const pagination = el.querySelector(".table-slider-pagination");

      const tableSlider = new Swiper(slider, {
        modules: [Navigation, Pagination, Autoplay],
        rewind: true,
        slidesPerView: "auto",
        spaceBetween: 8,

        speed: 1000,

        autoplay: {
          delay: 3000,
        },

        navigation: {
          nextEl: nextButton,
          prevEl: prevButton,
        },

        pagination: {
          el: pagination,
        },

        breakpoints: {
          // when window width is >= 744px
          744: {
            spaceBetween: 16,
          },

          1024: {
            spaceBetween: 16,
            slidesPerView: 2,
          },
        },
      });
    });
  }
}
