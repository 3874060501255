export const Burger = () => {
  const burgerBtn = document.querySelector(".header__burger")
  const headerContainder = document.querySelector(".header__container")
  const burgerMenu = document.querySelector(".burger-menu")

  burgerBtn.addEventListener("click", () => {
    if (headerContainder.classList.contains("--open")) {
      headerContainder.classList.remove("--open");
      burgerMenu.classList.remove("--open");
      document.body.classList.remove("disable-scroll");
    } else {
      headerContainder.classList.add("--open");
      burgerMenu.classList.add("--open");
      document.body.classList.add("disable-scroll");
    }
  })
}
