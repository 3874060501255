import Swiper from "swiper";
import { Navigation, Pagination, Autoplay, Grid } from 'swiper/modules';

export const BmPlatformSlider = () => {
  const bmPlatformSliders = document.querySelectorAll('.bm-platform .bm-platform__slider');
  bmPlatformSliders.forEach((slider) => {
    const nextElem = slider.querySelector('.bm-platform__next-button.--right');
    const prevElem = slider.querySelector('.bm-platform__prev-button.--left');
    const pagination = slider.querySelector('.bm-platform__pagination');
    const swiper = slider.querySelector('.swiper');

    const bmPlatformSlider = new Swiper(swiper, {
      modules: [Navigation, Pagination, Autoplay, Grid],
      rewind: true,
      slidesPerView: 2,
      spaceBetween: 7,

      grid: {
        fill: "row",
        rows: 2,
      },

      // speed: 1000,

      autoplay: {
        delay: 3000,
      },

      navigation: {
        nextEl: nextElem,
        prevEl: prevElem,
      },
      pagination: {
        el: pagination,
      },

      breakpoints: {
        // when window width is >= 744px
        576: {
          spaceBetween: 11,
        },
        // when window width is >= 744px
        744: {
          slidesPerView: 3,
          spaceBetween: 11,
        },
        // when window width is >= 1024px
        1024: {
          spaceBetween: 15,
          slidesPerView: 3,
        },
        // when window width is >= 1280px
        1280: {
          slidesPerView: 4,
          spaceBetween: 15,
        },
      }
    });
  })

}