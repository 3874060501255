import Inputmask from "inputmask";
export const ModalForm = () => {
  const modal = document.querySelector(".modal-form");
  if (!modal) {
    return;
  }
  const modalWraper = document.querySelector(".modal-wrapper");

  const openButtons = document.querySelectorAll(".form-button");
  const container = modal.closest(".fixed-container");

  const modalMask = container.querySelector(".modal-mask");
  const presentation = container.querySelector(".success-container");

  const modalSuccessMessage = container.querySelector(".success-container");

  const modalSuccessClose = modalSuccessMessage.querySelector(
    ".modal-success__close"
  );

  const modalSuccessButton = modalSuccessMessage.querySelector(
    ".modal-success__button"
  );

  const closeButton = document.querySelector(".modal-form__close");
  const form = modal.querySelector(".modal-form__form");

  const userName = document.querySelector("#user-name--modal");
  const nameField = userName.closest(".modal-form__input-wrapper");

  const userEmail = document.querySelector("#user-email--modal");
  const emailField = userEmail.closest(".modal-form__input-wrapper");

  const userPhone = document.querySelector("#user-phone--modal");
  const phoneField = userPhone.closest(".modal-form__input-wrapper");

  const agreementField = form
    .querySelector(".modal-form__checkbox")
    .closest(".modal-form__agreement");

  const telField = form.querySelector('input[type="tel"]');
  const letMask = new Inputmask("+7 (999) 999-99-99", {
    showMaskOnHover: false,
  });
  letMask.mask(telField);

  const nameReg = /^[A-Za-zА-Яа-яЁё]+$/;
  const emailReg = /^[A-Za-z0-9@.]+$/;
  const phoneReg = /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/;
  const emailReg2 = /[@]/;

  const clearFields = () => {
    const fields = form.querySelectorAll(".modal-form__input");
    fields.forEach((field) => {
      field.value = "";
      form.reset();
    });
  };

  form.addEventListener("submit", (evt) => {
    evt.preventDefault();

    if (userName.value.length === 0 || !nameReg.test(userName.value)) {
      nameField.classList.add("invalid-field");

      setTimeout(() => {
        nameField.classList.remove("invalid-field");
      }, 3000);
      return;
    } else if (
      userEmail.value.length === 0 ||
      !emailReg.test(userEmail.value) ||
      !emailReg2.test(userEmail.value)
    ) {
      emailField.classList.add("invalid-field");
      setTimeout(() => {
        emailField.classList.remove("invalid-field");
      }, 3000);
      return;
    } else if (
      userPhone.value.length === 0 ||
      !phoneReg.test(userPhone.value)
    ) {
      phoneField.classList.add("invalid-field");
      setTimeout(() => {
        phoneField.classList.remove("invalid-field");
      }, 3000);
      return;
    } else if (!form.querySelector(".modal-form__checkbox:checked")) {
      agreementField.classList.add("invalid-field");
      setTimeout(() => {
        agreementField.classList.remove("invalid-field");
      }, 3000);
      return;
    }

    modalSuccessButton.addEventListener("click", closeModal);
    modalSuccessClose.addEventListener("click", closeModal);

    presentation.style.order = "-1";
    modal.style.display = "none";
    modalSuccessMessage.style.display = "flex";
    modalSuccessMessage.style.position = "unset";
    modalWraper.style.justifyContent = "center";
    modalSuccessMessage.style.height = "fit-content";


    clearFields();
  });

  openButtons.forEach((button) => {
    button.addEventListener("click", () => {
      container.style.display = "flex";
      document.body.classList.add("disable-scroll");
    });
  });

  function closeModal() {
    container.style.display = "none";
    document.body.classList.remove("disable-scroll");
    modalSuccessMessage.style.display = "none";
    modal.style.display = "flex";
    modalWraper.style.justifyContent = "space-between";
  }

  closeButton.addEventListener("click", closeModal);
  modalMask.addEventListener("click", closeModal);
};
