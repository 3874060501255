export const ServicesFormDesktop = () => {
  const form = document.querySelector(".services-form__form--desktop");
  if (!form) {
    return;
  }

  const modalSuccessMessage = document.querySelector(
    ".success-container--global"
  );

  const modalSuccessClose = modalSuccessMessage.querySelector(
    ".modal-success__close"
  );
  const modalSuccessButton = modalSuccessMessage.querySelector(
    ".modal-success__button"
  );

  const userName = document.querySelector("#name--services");
  const nameField = userName.closest(".services-form__field");

  const userEmail = document.querySelector("#email--services");
  const emailField = userEmail.closest(".services-form__field");

  const nameReg = /^[A-Za-zА-Яа-яЁё]+$/;
  const emailReg = /^[A-Za-z0-9@.]+$/;
  const emailReg2 = /[@]/;

  const clearFields = () => {
    const fields = form.querySelectorAll(".suggest__input");
    fields.forEach((field) => {
      field.value = "";
    });
  };

  form.addEventListener("submit", (evt) => {
    evt.preventDefault();

    if (userName.value.length === 0 || !nameReg.test(userName.value)) {
      nameField.classList.add("invalid-field");

      setTimeout(() => {
        nameField.classList.remove("invalid-field");
      }, 3000);
      return;
    } else if (
      userEmail.value.length === 0 ||
      !emailReg.test(userEmail.value) ||
      !emailReg2.test(userEmail.value)
    ) {
      emailField.classList.add("invalid-field");
      setTimeout(() => {
        emailField.classList.remove("invalid-field");
      }, 3000);
      return;
    }

    modalSuccessMessage.style.display = "flex";
    clearFields();

    modalSuccessButton.addEventListener("click", () => {
      modalSuccessMessage.style.display = "none";
    });
    modalSuccessClose.addEventListener("click", () => {
      modalSuccessMessage.style.display = "none";
    });
  });
};
