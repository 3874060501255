import Swiper from "swiper";
import { Navigation, Pagination, Autoplay } from "swiper/modules";

export const ReviewsSlider = () => {
  const nextElem = document.querySelector(".reviews-slider__next-button");
  const prevElem = document.querySelector(".reviews-slider__prev-button");

  const reviewsSwiper = new Swiper(".reviews-slider", {
    modules: [Navigation, Pagination, Autoplay],
    rewind: true,
    slidesPerView: 1,
    spaceBetween: 16,

    speed: 500,

    autoplay: {
      delay: 3000,
    },

    navigation: {
      nextEl: nextElem,
      prevEl: prevElem,
    },
    pagination: {
      el: ".reviews-slider__pagination",
    },
    breakpoints: {
      1024: {
        slidesPerView: 2,
      },
      1280: {
        slidesPerView: 3,
      },
    },
  });
};
