export const DiscountTimer = () => {
  const timers = document.querySelectorAll(".timer");
  if (!timers) {
    return;
  }

  timers.forEach((timer) => {
    // Текущая дата
    let start_time = new Date();
    // Дата завтрашнего дня 00:00
    let end_time = start_time.setHours(24, 0, 0, 0);

    // запускаем ежесекундный отсчёт
    let countdown = setInterval(function () {
      // Текущая дата
      start_time = new Date();
      // Осталось времени
      let remain = end_time - start_time;

      let hour = Math.floor(
        (remain % (1000 * 60 * 60 * 60)) / (1000 * 60 * 60)
      );
      let min = Math.floor((remain % (1000 * 60 * 60)) / (1000 * 60));
      let sec = Math.floor((remain % (1000 * 60)) / 1000);

      // если значение текущей секунды меньше 10, добавляем вначале ведущий ноль
      sec = sec < 10 ? "0" + sec : sec;
      // если значение текущей минуты меньше 10, добавляем вначале ведущий ноль
      min = min < 10 ? "0" + min : min;
      // если значение текущего часа меньше 10, добавляем вначале ведущий ноль
      hour = hour < 10 ? "0" + hour : hour;

      // если время вышло
      if (remain < 0) {
        // останавливаем отсчёт
        clearInterval(countdown);
        // пишем текст вместо цифр
        timer.innerHTML = "Всё!";
      }
      timer.innerHTML = `${hour}:${min}:${sec}`;
    }, 1000);
  });
};
