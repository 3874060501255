export const СaseScroll = () => {
  const scrollButtons = document.querySelectorAll('[data-scroll-to]');
  scrollButtons.forEach(button => {
    button.addEventListener('click', (event) => {
      event.preventDefault();

      const tabButton = button.getAttribute('data-tab-to');
      const targetTab = document.querySelector(`[data-tabid="${tabButton}"]`);

      const targetId = button.getAttribute('data-scroll-to');
      if (targetId == "") {
        return false;
      }
      const targetElement = document.getElementById(targetId);


      if (targetElement && tabButton !== null) {
        targetTab.click();
        targetElement.scrollIntoView({ behavior: 'smooth' });
      } else {
        targetElement.scrollIntoView({ behavior: 'smooth' });
        const allTab = document.querySelector('[data-tabid="allProjects"]');
        allTab.click();
      }
    });
  });
};