import Swiper from "swiper";
import { Navigation, Pagination, Autoplay, Grid } from "swiper/modules";

export function ActivitiesSlider() {
  const slider = document.querySelector(".activities-slider");
  if (!slider) {
    return;
  }

  const slides = slider.querySelectorAll(".swiper-slide");

  slides.forEach((slide) => {
    const button = slide.querySelector(".activities-slider__show-more");
    if (!button) {
      return false;
    }
    button.addEventListener("click", (evt) => {
      const activeItem = document.querySelector(".swiper-slide.show");
      const currentItem = evt.currentTarget.closest(".swiper-slide");

      if (activeItem && activeItem !== currentItem) {
        activeItem.classList.remove("show");
        activeItem.querySelector(".activities-slider__show-more").textContent =
          "Показать все";
      }

      slide.classList.toggle("show");

      if (slide.classList.contains("show")) {
        slide.style.zIndex = 1000;
        button.textContent = "Скрыть все";
      } else {
        button.textContent = "Показать все";
        slide.style.zIndex = 0;
      }
    });
  });

  const activitiesSlider = new Swiper(slider, {
    modules: [Navigation, Pagination, Autoplay, Grid],
    rewind: true,
    slidesPerView: "auto",
    spaceBetween: 8,

    grid: {
      fill: "row",
      rows: 2,
    },

    speed: 1000,

    // autoplay: {
    //   delay: 3000,
    // },

    navigation: {
      nextEl: ".activities__next-button",
      prevEl: ".activities__prev-button",
    },
    pagination: {
      el: ".activities__pagination",
    },

    breakpoints: {
      // when window width is >= 744px
      744: {
        slidesPerView: "auto",
        spaceBetween: 16,
        grid: {
          fill: "row",
          rows: 2,
        },
      },
      // when window width is >= 1024px
      1024: {
        slidesPerView: 4,
        spaceBetween: 16,
        grid: {
          fill: "row",
          rows: 2,
        },
      },
      // when window width is >= 1280px
      1439: {
        slidesPerView: 5,
        spaceBetween: 16,
        grid: {
          fill: "row",
          rows: 2,
        },
      },
    },
  });
}
